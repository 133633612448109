import { useSelector } from 'react-redux';
// Material UI
import { Box, styled } from '@mui/material';

// Custom Components
import JamrulCard from './jamrulCard';

// Material Styled Components
const MasterWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: theme.deminsions.fixedWidth,
  margin: 'auto',
}));

const SectionTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  textAlign: 'center',
  marginBottom: '25px',
  '.sectionTitleCaption': { color: '#d6d6d6' },
  '.sectionTitleSummary': { color: theme.palette.primary.main, fontSize: '30px', fontWeight: 'bold' },
  '.sectionTitleBar': { margin: 'auto', backgroundColor: theme.palette.primary.black, height: '2px' },
}));

const CardWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
  flex: 1,
  paddingLeft: 20,
  paddingRight: 20,
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
  },
}));

// Primary Component
const ServicesCards = () => {
  const services = useSelector((state) => state.services.services);
  return (
    <MasterWrapper>
      <SectionTitle>
        <span className='sectionTitleCaption'>What We Do</span>
        <h2 className='sectionTitleSummary'>OUR SERVICES</h2>
        <span className='sectionTitleBar' style={{ width: '150px' }}></span>
      </SectionTitle>

      <CardWrapper>
        {services.map((card) => (
          <JamrulCard
            key={card.id}
            title={card.title}
            summary={card.summary}
            buttonText={card.buttonText}
            minHeight='400px'
            topImagePath={`/images/services_cards/top_images/${card.id}.jpg`}
            link={`/services/${card.id}`}
          />
        ))}
      </CardWrapper>
    </MasterWrapper>
  );
};
export default ServicesCards;
