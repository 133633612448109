// Material UI
import { Box, Divider, styled, useTheme } from '@mui/material';
import GeneralHeader from '../generalHeader';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  '.inner': {
    maxWidth: theme.deminsions.fixedMaxWidth,
    margin: 'auto',
    padding: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}));

const PartnerProfiles = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  gap: 20,
  textAlign: 'center',
  marginBottom: 20,
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },

  '.partner_profiles_image_wrapper': {
    img: {
      width: '150px',
      height: '200px',
      objectFit: 'cover',
      border: `solid 3px ${theme.palette.primary.main}`,
      borderRadius: 12,
      boxShadow: '2px 2px 8px rgba(0,0,0,.4)',
    },
  },
  '.partner_profiles_info_wrapper': {
    flex: 1,
    textAlign: 'left',
  },
}));

// Primary Component
const ContactUsPartners = () => {
  const theme = useTheme();
  return (
    <Wrapper>
      <Box className='inner'>
        <Box sx={{ gridColumn: '1 / -1', marginBottom: '25px', textAlign: 'center' }}>
          <GeneralHeader text='Pelican Lawn Company' spanText='Partners' />
        </Box>
        <Box sx={{ gridColumn: { xs: '1 / -1', md: '1/2' }, textAlign: 'center' }}>
          <PartnerProfiles>
            <Box className='partner_profiles_image_wrapper'>
              <img src='/images/profile_images/benji_profile_1.jpg' alt='benjis profile' />
            </Box>
            <Box className='partner_profiles_info_wrapper'>
              <h2>Benji O'Con</h2>
              <h3 style={{ color: theme.palette.primary.main }}>Flowerbed Specialist</h3>
              <a style={{ color: theme.palette.primary.black, textDecoration: 'none' }} href='tel:3182103554'>
                318.210.3554
              </a>
              <br></br>
              <a
                style={{ color: theme.palette.primary.black, textDecoration: 'none', margin: '10px 0', lineHeight: 1, display: 'block', fontWeight: '700' }}
                href='mailto:benji.ocon@pelicanlawncompany.com'
              >
                Benji.Ocon<span style={{ color: theme.palette.primary.main }}>@</span>
                <br></br>
                PelicanLawnCompany.com
              </a>
            </Box>
          </PartnerProfiles>
        </Box>
        <Divider sx={{ display: { xs: 'block', sm: 'none' }, margin: '18px 0 25px 0', gridColumn: '1/-1' }} />
        <Box sx={{ gridColumn: { xs: '1 / -1', md: '2/3' }, textAlign: 'center' }}>
          <PartnerProfiles>
            <Box className='partner_profiles_image_wrapper'>
              <img src='/images/profile_images/caleb_profile_1.jpg' alt='benjis profile' />
            </Box>
            <Box className='partner_profiles_info_wrapper'>
              <h2>Caleb Hough</h2>
              <h3 style={{ color: theme.palette.primary.main }}>Lawn Care Specialist</h3>
              <a style={{ color: theme.palette.primary.black, textDecoration: 'none' }} href='tel:3182860880'>
                318.286.0880
              </a>
              <br></br>
              <a
                style={{ color: theme.palette.primary.black, textDecoration: 'none', margin: '10px 0', lineHeight: 1, display: 'block', fontWeight: '700' }}
                href='mailto:caleb.hough@pelicanlawncompany.com'
              >
                Caleb Hough<span style={{ color: theme.palette.primary.main }}>@</span>
                <br></br>
                PelicanLawnCompany.com
              </a>
            </Box>
          </PartnerProfiles>
        </Box>
      </Box>
    </Wrapper>
  );
};
export default ContactUsPartners;
