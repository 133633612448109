import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// MATERIAL UI ASSETS
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MfrPortalThemeSchema } from './muiThemes/mfr_portal_template_theme';
import { Box, styled } from '@mui/material';
import './muiThemes/mfr_portal.styles.scss';

// Custom Components
import HeaderBar from './components/headerBar';
import HomeHero from './components/homeHero';
import ServicesCards from './components/servicesCards';
import ServicePage from './components/servicePage';
import GeneralContainer from './components/generalContainer';
import ContactUsIndex from './components/contact_us/index';
import ContactUsPage from './components/contact_us/contact_us_page';

const theme = createTheme(MfrPortalThemeSchema[0]);

// Material Styled Components
const MasterWrapper = styled(Box)({
  height: '100vh',
  // eslint-disable-next-line
  height: '100dvh',
  backgroundColor: theme.palette.primary.white,
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr',
});

const TopBar = styled(Box)({ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.white, fontWeight: 'bold', textAlign: 'center', fontSize: '.8em', padding: '4px 0' });

const Content = styled(Box)({ backgroundColor: theme.palette.primary.white, color: theme.palette.primary.black, overflowY: 'auto' });

const Footer = styled(Box)({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.white,
  textAlign: 'center',
  fontSize: '.8em',
  fontWeight: 'bold',
  padding: '9px 10px',
  marginTop: '25px',
});

// Primary Component
const GlobalIndex = () => {
  return (
    <ThemeProvider theme={theme}>
      <MasterWrapper>
        <TopBar>Spring Specials Going On NOW! </TopBar>
        <HeaderBar />

        <Content id='mainContentWrapper'>
          <Routes>
            <Route
              path='/'
              element={
                <React.Fragment>
                  <HomeHero />

                  <ContactUsIndex />
                </React.Fragment>
              }
            />
            <Route path='/about-us' element={<h1>All About Us</h1>} />
            <Route path='/services/:id' element={<ServicePage />} />
            <Route path='/contact-us/' element={<ContactUsPage />} />
            <Route path='/*' element={<h1>All Other Routes</h1>} />
          </Routes>
          <GeneralContainer fixedMaxWidth={theme.deminsions.fixedMaxWidth}>
            <ServicesCards />
          </GeneralContainer>
          <Footer>Copyright &copy; 2023 by Pelican Lawn Company, LLC</Footer>
        </Content>
      </MasterWrapper>
    </ThemeProvider>
  );
};
export default GlobalIndex;
