import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Custom Components
import HomeHero from './homeHero';
import GeneralContainer from './generalContainer';

// Material UI
import { Box, styled, Typography, useTheme } from '@mui/material';

// Material Styled Components
const MasterWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  //maxWidth: theme.deminsions.fixedWidth,
  margin: 'auto',
  '.title': {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    margin: '20px 0',
    color: theme.palette.primary.accentOne,
  },
  [theme.breakpoints.up('sm')]: {
    '.title': {
      fontSize: '2.2rem',
    },
  },
  '.desc': {
    lineHeight: '1.5',
    marginBottom: 45,
  },
}));

// Primary Component
const ServicePage = () => {
  const theme = useTheme();
  let { id } = useParams();
  const services = useSelector((state) => state.services.services);

  return (
    <>
      <MasterWrapper data-identifier='Service Page Master Wrapper'>
        <HomeHero />
        <GeneralContainer fixedMaxWidth={theme.deminsions.fixedMaxWidth}>
          {services
            .filter((item) => item.id === parseInt(id))
            .map((service) => (
              <Box key={service.id} sx={{ padding: '15px' }}>
                <Typography className='title'>{service.title}</Typography>
                <Typography className='desc'>{service.details}</Typography>
              </Box>
            ))}
        </GeneralContainer>
      </MasterWrapper>
    </>
  );
};
export default ServicePage;
