// Material UI
import { Box, styled } from '@mui/material';

// Material Styled Components

const Wrapper = styled(Box)(({ theme, fixedMaxWidth }) => ({
  '.inner': {
    maxWidth: fixedMaxWidth ? fixedMaxWidth : '900px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

// Primary Component
const GeneralContainer = ({ children, fixedMaxWidth }) => {
  console.log('passes value ' + fixedMaxWidth);
  return (
    <Wrapper fixedMaxWidth={fixedMaxWidth}>
      <Box className='inner'>{children}</Box>
    </Wrapper>
  );
};
export default GeneralContainer;
