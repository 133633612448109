// Material UI
import { Box, styled } from '@mui/material';

// Custom Components
import GeneralHeader from '../generalHeader';
import ContactUsForm from './contactUsForm';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme, bgColor }) => ({
  padding: 15,
  backgroundColor: bgColor ? bgColor : theme.palette.primary.white,

  '.inner': {
    maxWidth: theme.deminsions.fixedMaxWidth,
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 20,
  },
  '.threeImages': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 15,
  },
  '.imgContainer': {
    flex: 1,
  },
  '.imgWrapper': {
    width: '100%',
    height: '0',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'green',
    paddingTop: '75%',
    '& > img': {
      display: 'block',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
}));

// Primary Component
const ContactUsIndex = ({ bgColor }) => {
  return (
    <Wrapper bgColor={bgColor}>
      <Box className='inner'>
        <Box sx={{ gridColumn: '1/-1', textAlign: 'center' }}>
          <GeneralHeader text='We Would Love To' spanText='Hear From You!' />
        </Box>
        <Box sx={{ gridColumn: { xs: '1/3', md: '1/2' } }}>
          <Box className='threeImages'>
            <Box className='imgContainer'>
              <Box className='imgWrapper'>
                <img src='/images/services_cards/top_images/0.jpg' alt='about us thumbnail 1' />
              </Box>
            </Box>
            <Box className='imgContainer'>
              <Box className='imgWrapper'>
                <img src='/images/services_cards/top_images/1.jpg' alt='about us thumbnail 2' />
              </Box>
            </Box>
            <Box className='imgContainer'>
              <Box className='imgWrapper'>
                <img src='/images/services_cards/top_images/4.jpg' alt='about us thumbnail 3' />
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: '15px 5px', lineHeight: '1.3' }}>
            <span style={{ fontWeight: 700 }}>Pelican Lawn Company of Shreveport</span> is committed to delivering top-notch services to our customers at reasonable prices. Our skilled and experienced
            team is well-equipped to handle various outdoor needs such as lawn cutting, flowerbed cleaning, and other related services.
          </Box>

          <Box sx={{ padding: '5px', lineHeight: '1.3', fontWeight: '700' }}>
            We understand that maintaining a well-manicured lawn and garden is not only visually appealing but also adds value to a property.
          </Box>
        </Box>
        <Box sx={{ gridColumn: { xs: '1/3', md: '2/3' } }}>
          <ContactUsForm />
        </Box>
      </Box>
    </Wrapper>
  );
};
export default ContactUsIndex;
