// Material UI
import { Box, styled } from '@mui/material';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.black,
  fontSize: '1.8rem',
  '& > span': {
    color: theme.palette.primary.main,
  },
}));

// Primary Component
const GeneralHeader = ({ text, spanText }) => {
  return (
    <Wrapper>
      {text}
      {spanText && <span> {spanText}</span>}
    </Wrapper>
  );
};
export default GeneralHeader;
