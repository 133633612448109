import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI
import { Box, Button, styled, useMediaQuery, useTheme } from '@mui/material';

// Material Styled Components
const MasterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    '.fullCard': { backgroundColor: theme.palette.primary.black },
    '&:nth-of-type(even)': {
      '.fullCard': { backgroundColor: theme.palette.primary.main },
    },
  },
}));

const MobileCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minHeight: 80,
  flex: 1,
  borderRadius: 12,
  borderColor: theme.palette.primary.main,
  borderWidth: 2,
  borderStyle: 'solid',
  backgroundColor: theme.palette.primary.black,
  overflow: 'hidden',
  '.mobileLabel': {
    position: 'relative',
    zIndex: 1,
    fontSize: '1.5em',
    padding: 15,
    color: theme.palette.primary.white,
    textShadow: '2px 2px 4px rgba(0,0,0,.7)',
  },
  img: { width: '100%', height: '100%', objectFit: 'cover', transition: '500ms', zIndex: '0', position: 'absolute', opacity: '1' },
}));

const FullCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '.top': {
    position: 'relative',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '&:after': {
      content: `""`,
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: theme.palette.primary.main,
      opacity: '.6',
      width: '100%',
      height: '100%',
      transition: '500ms',
      transformOrigin: 'center top',
      transform: 'translate(-50%,-50%) scale(0)',
    },
    img: { width: '100%', height: '100%', objectFit: 'cover', transition: '500ms' },
  },
  '.bottom': {
    padding: '15px',
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '.title': { marginBottom: '12px', textAlign: 'center', fontSize: '1.4em', color: theme.palette.primary.white },
    '.text': { marginBottom: '12px', textAlign: 'center', lineHeight: '1.5', color: theme.palette.primary.white },
    '.ctaButton': {
      margin: 'auto',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: theme.palette.primary.white,
      transition: '500ms',
      '&:hover': {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.main,
      },
    },
  },
  '&:nth-of-type(even) .ctaButton': {
    backgroundColor: theme.palette.primary.black,
  },
  '&:hover': {
    '.top img': { transform: 'scale(1.2)' },
    '.top:after': { transform: 'translate(-50%,-50%) scale(1)' },
  },
}));

// Primary Component
const JamrulCard = ({ topImageUrl, title, summary, buttonText, minHeight, minWidth, maxWidth, topImagePath, link }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [initHit, setInitHit] = useState(0);

  useEffect(() => {
    const contentWrapper = document.querySelector('#mainContentWrapper');
    contentWrapper.scrollTo(0, 0);
    console.log('init');
  }, [initHit]);

  const onClickHandler = (link) => {
    setInitHit(initHit + 1);
    navigate(link);
  };

  return (
    <MasterWrapper data-identifier='jamrulCardWrapper'>
      {mobile ? (
        <MobileCard onClick={() => onClickHandler(link)}>
          <Box className='mobileLabel'>{title}</Box>
          <img src={topImagePath} alt={title} />
        </MobileCard>
      ) : (
        <FullCard className='fullCard' sx={{ minHeight: minHeight ? minHeight : '250px', minWidth: minWidth ? minWidth : '285px', maxWidth: maxWidth ? maxWidth : '350px' }}>
          <Box className='top'>
            <img src={topImagePath} alt={title} />
          </Box>
          <Box className='bottom'>
            <h3 className='title'>{title}</h3>
            <p className='text'>{summary}</p>
            <Box sx={{ textAlign: 'center', marginTop: 'auto' }}>
              <Button className='ctaButton' variant='contained' onClick={() => onClickHandler(link)}>
                {buttonText}
              </Button>
            </Box>
          </Box>
        </FullCard>
      )}
    </MasterWrapper>
  );
};
export default JamrulCard;
