import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  services: [
    {
      id: 0,
      title: 'LAWN CARE SERVICES',
      summary: 'Our lawn care services are aimed at providing comprehensive lawn care maintenance that keeps your lawn looking well-manicured and visually appealing.',
      details:
        "Pelican Lawn Company specializes in lawn care services that involve cutting the lawn, weed-eating, edging, and blowing. Our services are aimed at providing comprehensive lawn care maintenance that keeps your lawn looking well-manicured and visually appealing. We take pride in our work and have the experience and skills needed to ensure that every job is executed with precision and care. Whether you require a one-time service or recurring appointments, We're dedicated to providing high-quality lawn care services that meet and exceed customer expectations.",
      buttonText: 'Learn More',
    },
    {
      id: 1,
      title: 'FLOWERBED CLEAN-UP & INSTALLATION',
      summary: 'Our team is equipped with the necessary tools and knowledge to clean up and maintain your flowerbeds, ensuring that they look their best throughout the year.',
      details:
        'Pelican Lawn Company also provides flowerbed clean-up and installation services. Our team of experts is equipped with the necessary tools and knowledge to clean up and maintain your flowerbeds, ensuring that they look their best throughout the year. Additionally, if you need new flowerbeds installed, we can assist you in choosing the best design for your outdoor space and installing them professionally. Our flowerbed installation services are tailored to meet the individual needs and preferences of our clients, ensuring that the end result is a beautiful and vibrant flowerbed that complements the overall look of your lawn.',
      buttonText: 'Learn More',
    },
    {
      id: 2,
      title: 'MAINTENANCE PLANS',
      summary:
        'We offer maintenance plans that are customized to meet the specific needs of each client. These plans provide regular and ongoing services that are designed to keep your lawn and flowerbeds healthy and well-maintained throughout the year.',
      details:
        'Pelican Lawn Company offers maintenance plans that are customized to meet the specific needs of each client. These plans provide regular and ongoing services that are designed to keep your lawn and flowerbeds healthy and well-maintained throughout the year. Our maintenance plans typically include lawn cutting, weedeating, edging, blowing, flowerbed clean-up, and other related services. Our team works with clients to develop a customized maintenance plan that suits their needs and budget, ensuring that they receive the best possible care for their outdoor space. With our maintenance plans, clients can enjoy a beautiful and healthy lawn and flowerbeds without the stress and hassle of maintaining it themselves.',
      buttonText: 'Learn More',
    },
    {
      id: 3,
      title: 'PRESSURE WASHING',
      summary:
        'We provided pressure washing services to help clients maintain the exterior of their homes and outdoor living spaces. Our pressure washing services are designed to remove dirt, grime, and other build-ups from surfaces such as decks, patios, driveways, and siding.',
      detials:
        'Pelican Lawn Company also provides pressure washing services to help clients maintain the exterior of their homes and outdoor living spaces. Our pressure washing services are designed to remove dirt, grime, and other build-ups from surfaces such as decks, patios, driveways, and siding. Using high-pressure water jets, we can effectively clean and restore surfaces to near original condition, leaving them looking like new. Our team of professionals are skilled and experienced in pressure washing, ensuring that they use the appropriate pressure and techniques to avoid damaging surfaces. Our pressure washing services are an excellent way to enhance the curb appeal of your home and keep your outdoor living spaces looking their best.',
      buttonText: 'Learn More',
    },
    {
      id: 4,
      title: 'PROPERTY CLEAN-UP',
      summary:
        'We offer property clean-up services to help clients maintain a clean and well-organized outdoor space. Our property clean-up services typically involve removing debris, such as fallen branches, leaves, and other yard waste from the property.',
      details:
        'Pelican Lawn Company offers property clean-up services to help clients maintain a clean and well-organized outdoor space. Our property clean-up services typically involve removing debris, such as fallen branches, leaves, and other yard waste from the property. They also provide services for clearing out overgrown areas, trimming hedges and bushes, and removing unwanted plants and weeds. Our team of experts work with clients to develop a customized plan that meets their specific needs and budget, ensuring that their outdoor space is always clean and well-maintained. Our property clean-up services are an excellent option for clients who want to maintain a clean and organized outdoor space without the hassle and stress of doing it themselves.',
      buttonText: 'Learn More',
    },
    {
      id: 5,
      title: 'ALL THE ABOVE!',
      summary: "We're dedicated to providing clients with top-quality services, no matter the size of the job. We understand that every outdoor space is unique and has its own specific needs.",
      details:
        "Pelican Lawn Company is dedicated to providing clients with top-quality service, no matter the size of the job. We understand that every outdoor space is unique and has its own specific needs. Therefore, we are committed to providing a comprehensive range of services that cater to all outdoor needs, whether it's a small job or a large one. Our team of experts is equipped with the necessary tools and knowledge to handle any outdoor project, from lawn cutting and flowerbed maintenance to property clean-up and pressure washing. We work closely with our clients to ensure that their needs and preferences are met, and their outdoor space is well-maintained and visually appealing. We take pride in delivering high-quality service to our clients, no matter the size of the job.",
      buttonText: 'Learn More',
    },
  ],
};
export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    servicesValue: (state, action) => {
      state.services = action.payload;
    },
  },
});

export const { servicesValue } = servicesSlice.actions;

export default servicesSlice.reducer;
