import { grey } from '@mui/material/colors';

export const MfrPortalThemeSchema = [
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1600,
      },
    },
    deminsions: {
      fixedMaxWidth: '1200px',
    },
    palette: {
      text: {
        primary: '#666666',
      },
      primary: {
        main: '#006837',
        light: '#C3CDF0',
        xlight: '#dee3f5',
        dark: '#002998',
        black: '#1e1e1e',
        lightGray: '#afafaf',
        white: '#ffffff',
        transparent: '#ffffff00',
        contrastText: '#ffffff',
        accentOne: '#edbd45',
        primaryButtonHover: '#02301a',
      },
      notification: {
        main: '#ff00ff',
        light: '#dee3f5',
        xlight: '#dee3f5',
        dark: '#ff0000',
        contrastText: '#ffffff',
      },
      transparent: {
        white_10: '#ffffffe6',
        white_20: '#ffffffcc',
        white_30: '#ffffffb3',
        white_40: '#ffffff99',
        white_50: '#ffffff80',
        white_60: '#ffffff66',
        white_70: '#ffffff4d',
        white_80: '#ffffff33',
        white_90: '#ffffff1a',
      },
      ourColor: {
        secondary: 'rgb(79, 172, 253)',
        border_grey: grey[400],
        primary_font_color: '#7c7c7c',
        screen_color_01: grey[300],
        screen_color_02: '#deefe7',
      },
    },
  },
];
