import React, { useState } from 'react';
import axios from 'axios';

// Material UI
import { Box, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, styled, Slide, TextField, Typography, useTheme } from '@mui/material';
import { Send } from '@mui/icons-material';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
  marginBottom: 20,
  '.Inner': {
    border: `solid 1px ${theme.palette.ourColor.border_grey}`,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 15,
    padding: 20,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

// Primary Component
const ContactUsForm = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [formName, setFormName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formPhone, setFormPhone] = useState('');
  const [formSubject, setFormSubject] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [formNewsLetterCheckBox, setFormNewsLetterCheckBox] = useState(true);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const postJoinNewsLetter = async (e) => {
    e.preventDefault();

    axios
      .post('/aws/ses/simple-mail', {
        to: 'benji.ocon@pelicanlawncompany.com,tony.ocon@oconfamily.net',
        from: 'benji.ocon@pelicanlawncompany.com',
        subject: 'News Letter Submission',
        id: '1001',
        markup: `
        <h1>${formName} has requested to get your newsletter</h1><ul><li>${formEmail}</li><li>${formPhone}<li>${formSubject}</li></ul><p>${formMessage}</p><h4>Want to get email? ${formNewsLetterCheckBox}</h4>
        `,
      })
      .then(function (response) {
        console.log('response: ' + response);
        setOpen(true);
      })
      .catch(function (error) {
        console.log('something went wrong: ' + error);
      });
  };

  return (
    <Wrapper>
      <Box className='Inner'>
        <Box className='inputWrapper' sx={{ gridColumn: { xs: '1/-1', md: '1/2' } }}>
          <TextField fullWidth label='Your Name' type='text' value={formName} onChange={(e) => setFormName(e.target.value)} />
        </Box>
        <Box className='inputWrapper' sx={{ gridColumn: { xs: '1/-1', md: '2/3' } }}>
          <TextField fullWidth label='Email' type='text' value={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
        </Box>
        <Box className='inputWrapper' sx={{ gridColumn: { xs: '1/-1', md: '1/2' } }}>
          <TextField fullWidth label='Phone Number' type='text' value={formPhone} onChange={(e) => setFormPhone(e.target.value)} />
        </Box>
        <Box className='inputWrapper' sx={{ gridColumn: { xs: '1/-1', md: '2/3' } }}>
          <TextField fullWidth label='Subject' type='text' value={formSubject} onChange={(e) => setFormSubject(e.target.value)} />
        </Box>
        <Box className='inputWrapper' sx={{ gridColumn: '1/-1' }}>
          <TextField fullWidth label='Message' type='text' value={formMessage} multiline minRows={2} maxRows={4} onChange={(e) => setFormMessage(e.target.value)} />
        </Box>
        <Box sx={{ gridColumn: '1/-1', marginTop: '15px', padding: '0 15px' }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} md={10}>
              <Grid container>
                <Checkbox
                  value='newsletter checkbox'
                  inputProps={{
                    'aria-label': 'News Letter Checkbox',
                  }}
                  defaultChecked
                  onChange={(e) => setFormNewsLetterCheckBox(e.target.checked)}
                />
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ color: theme.palette.primary.main, fontWeight: 700, fontSize: '1.3em' }}>JOIN OUR NEWSLETTER!</Typography>
                  <Typography>
                    Your information is used for
                    <span style={{ color: theme.palette.primary.main, fontWeight: 700 }}> Pelican Lawn Company</span> business only and is never sold or shared.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} sx={{ textAlign: 'right' }}>
              {/* <Button variant='contained' onClick={() => console.log('form submitted')}>
                Send Message
              </Button> */}
              <IconButton
                aria-label='delete'
                sx={{ backgroundColor: theme.palette.primary.main, color: 'white', '&:hover': { backgroundColor: theme.palette.primary.primaryButtonHover } }}
                onClick={(e) => postJoinNewsLetter(e)}
              >
                <Send />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleDialogClose} aria-describedby='alert-dialog-slide-description'>
        <DialogTitle>Thank you for reaching out to us!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>Your request will be responded to very soon!</DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions> */}
      </Dialog>
    </Wrapper>
  );
};

export default ContactUsForm;
