// Custom Components
import ContactUsIndex from '.';
import HomeHero from '../homeHero';

// Material UI
import { Box, styled, useTheme } from '@mui/material';
import ContactUsPartners from './contact_us_partners';

// Material UI Styled Components
const Wrapper = styled(Box)(({ theme }) => ({}));

// Primary Component
const ContactUsPage = () => {
  const theme = useTheme();
  return (
    <Wrapper>
      <HomeHero bgUrl={'/images/services_cards/top_images/4.jpg'} />
      <ContactUsPartners />
      <ContactUsIndex bgColor={theme.palette.ourColor.screen_color_02} />
    </Wrapper>
  );
};
export default ContactUsPage;
