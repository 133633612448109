// Material UI
import { Box, Button, styled, useTheme } from '@mui/material';

import home_hero_xl from './../images/home_hero/xl.jpg';

// Material Styled Components
const MasterWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.black,
  marginBottom: '20px',
  position: 'relative',
  '.screen': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    zIndex: 0,
    opacity: 0.5,
  },
}));

const CentralText = styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: 'relative',
  gridColumn: '1/-1',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '45px 20px',
  alignItems: 'center',
  textAlign: 'center',
  h2: { textTransform: 'uppercase', color: theme.palette.primary.accentOne, lineHeight: 1 },
  p: { color: theme.palette.primary.white },
  [theme.breakpoints.up('sm')]: {
    gridColumn: ' 3 / 11',
    padding: '100px 0px',
    h2: { fontSize: '2.5em' },
    p: { maxWidth: '600px', margin: 'auto' },
  },
  [theme.breakpoints.up('lg')]: {
    padding: '200px 0px',
    h2: { fontSize: '2.8em' },
    p: { maxWidth: '700px', margin: 'auto' },
  },
}));

// Primary Component
const HomeHero = ({ bgUrl }) => {
  const theme = useTheme();
  return (
    <MasterWrapper>
      <img className='screen' src={bgUrl ? bgUrl : home_hero_xl} alt='Home Hero' />
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', maxWidth: theme.deminsions.fixedMaxWidth, margin: 'auto' }}>
        <CentralText>
          <h2>Welcome To Pelican Lawn and Flowerbed Maintenance</h2>
          <p>When it comes to Lawn and Flowerbed Maintenance, we're committed to delivering top-notch services to its customers at reasonable prices.</p>
          <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
            <Button
              variant='contained'
              size='large'
              sx={{ border: `solid 2px ${theme.palette.primary.white}`, '&:hover': { backgroundColor: theme.palette.primary.white, color: theme.palette.primary.main } }}
            >
              Get In Touch!
            </Button>
            {/* <Button variant='contained'>Learn More...</Button> */}
          </Box>
        </CentralText>
      </Box>
    </MasterWrapper>
  );
};
export default HomeHero;
