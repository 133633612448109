import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Material UI
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Home, Menu, Send } from '@mui/icons-material';

// Material Styled Components
const MasterWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.primary.main,
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: theme.deminsions.fixedMaxWidth,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  justifyContent: 'space-evenly',
}));

const NameNumberWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 15,
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
}));

// Primary Component
const HeaderBar = () => {
  const testRTK = useSelector((state) => state.services.test);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const routeParams = useParams();
  const { id } = routeParams;
  console.log('id: ' + id);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleNavClick = (nav) => {
    navigate(nav);
    setOpenDrawer(false);
  };

  return (
    <MasterWrapper data-slice-test={testRTK}>
      <InnerWrapper>
        <Box sx={{ padding: '6px 4px 5px 6px', flex: 1 }}>
          {/* <img src='/images/logo_v2.png' alt='Pelican Lawn Care of Shreverport' onClick={() => navigate('/')} /> */}
          <img style={{ height: 48 }} src='/images/logos/pelican_logo_v2.png' alt='Pelican Lawn Care of Shreverport' onClick={() => navigate('/')} />
          {/* <img src='/images/logos/logo_svg.svg' alt='Pelican Lawn Care of Shreverport' onClick={() => navigate('/')} /> */}
        </Box>
        {!isMobile && (
          <NameNumberWrapper>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0', padding: '7px 0' }}>
              <Typography sx={{ color: theme.palette.primary.main, textTransform: 'uppercase', fontSize: '1.25rem' }}>Benji O'Con</Typography>
              <Typography sx={{ lineHeight: '.5', color: theme.palette.primary.lightGray, fontSize: '.9rem' }}>
                <a style={{ color: theme.palette.primary.black, textDecoration: 'none' }} href='tel:3182103554'>
                  318.210.3554
                </a>
              </Typography>
            </Box>
            <Divider orientation='vertical' flexItem />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0' }}>
              <Typography sx={{ color: theme.palette.primary.main, textTransform: 'uppercase', fontSize: '1.25rem' }}>Caleb Hough</Typography>
              <Typography sx={{ lineHeight: '.5', color: theme.palette.primary.lightGray, fontSize: '.9rem' }}>
                <a style={{ color: theme.palette.primary.black, textDecoration: 'none' }} href='tel:3182860880'>
                  318.286.0880
                </a>
              </Typography>
            </Box>
          </NameNumberWrapper>
        )}
        <Box sx={{ flex: 1, color: theme.palette.primary.main, textAlign: 'right' }}>
          <Menu sx={{ fontSize: '2.2rem', marginRight: '20px' }} onClick={() => setOpenDrawer(true)} />
        </Box>
      </InnerWrapper>
      <Drawer anchor='right' open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box sx={{ padding: '20px' }}>
          <nav aria-label='main mailbox folders'>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleNavClick('/')}>
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary='Home Page' />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleNavClick('/contact-us')}>
                  <ListItemIcon>
                    <Send />
                  </ListItemIcon>
                  <ListItemText primary='Contact Us' />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      </Drawer>
    </MasterWrapper>
  );
};
export default HeaderBar;
