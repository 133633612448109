import React from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import './App.css';

// Custom Components
import GlobalIndex from './app/global/index';
console.log('windows host: ' + window.location.host);

// SETTING AXIOS's baseURL for PRODUCTION
if (window.location.host !== 'localhost:3000') {
  console.log(' am using live node');
  axios.defaults.baseURL = 'https://ocon.co';
} else {
  console.log(' am using local node');
}

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/*' element={<GlobalIndex />} />
      </Routes>
    </div>
  );
}

export default App;
